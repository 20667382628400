
const confirmUser = async (token) => {
    let response = await fetch(`${process.env.REACT_APP_API_WEB_BOOT}user/confirm?token=${token}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (response.status !== 200) {
        return false;
    }
    response = response.json();
    return response;
}

export default confirmUser