const userIsFms = async (email) => {
    let response = await fetch(`https://fastmediasocial.com.br/api/admin/user/status?email=${email}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    response = response.json();

    return response;
}

export default userIsFms