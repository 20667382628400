import QrCode from './qrCode';
const home = () => {
    return (
        <div className="container-fluid flex-container">
        <QrCode/>
            <div className='help-user'>
                <a target='_blank' href="https://www.youtube.com/@Platformbot-um5ok/videos" ><img className='help-img-yt' src="/assets/images/1384060.png" />Aprenda usar</a>
            </div>
        </div>
    )
}

export default home