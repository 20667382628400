import React, { useEffect, useState } from 'react';
import confirmUser from '../../helper/user/confirmUser'
import { useLocation } from 'react-router-dom';

const Confirm = () => {
  const location = useLocation();
  const [message, setMessage] = useState("Aguarde, estamos confirmando seu registro...");

  const confirmLogin = async () => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    if(!token){
      setMessage("Não foi possivel confirmar seu registro, entre em contato com o administrador do sistema")
      return
    }
    const response = await confirmUser(token)
 
    if(response){
      setMessage("Registro confirmado com sucesso, realize o login")
      return
    }
    setMessage("Não foi possivel confirmar seu registro, entre em contato com o administrador do sistema")
  }

  useEffect(() => {
    confirmLogin()
  },[])


  return (
    <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
      data-sidebar-position="fixed" data-header-position="fixed">
      <div
        class="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
        <div class="d-flex align-items-center justify-content-center w-100">
          <div class="row justify-content-center w-100">
            <div class="col-md-8 col-lg-6 col-xxl-3">
              <div class="card mb-0">
                <div class="card-body">
                  <a href="./index.html" class="text-nowrap logo-img text-center d-block py-3 w-100">
                    <img src="../assets/images/logos/platformbotlogo.png" width="180" alt="" />
                  </a>
                    <p>{message}</p>
                    <a class="text-primary fw-bold " href="login">Realizar Login</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Confirm