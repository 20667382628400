import React, { useState } from 'react';
import createdUser from '../../helper/user/createdUser';

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [messageError, setMessageError] = useState();
  const [messageSuccess, setMessageSuccess] = useState();

  const handlePhone = (event) => {
    let input = event.target;
    let value = input.value;

    if (value.length > 15) {
      value = value.substring(0, 14);
    }
    setPhone(value);
    input.value = phoneMask(value);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const createdNewUser = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessageError();
    setNameError(false);
    setEmailError(false);
    setPhoneError(false);
    setPasswordError(false);
    // Form validation
    if (!name || !email || !phone || !password) {
      setNameError(!name);
      setEmailError(!email);
      setPhoneError(!phone);
      setPasswordError(!password);
      setLoading(false);
      return;
    }

    if (phone.length < 14 || phone.length > 15) {
      setPhoneError(true);
      setMessageError("O telefone deve ter entre 14 e 15 caracteres.");
      setLoading(false);
      return;
    }

    if (!isValidEmail(email)) {
      setEmailError(true);
      setMessageError("Por favor entre com um email valido");
      setLoading(false);
      return;
    }

    // Remover espaços, () e -
    const cleanedPhone = phone.replace(/\s/g, '').replace(/[()-]/g, '');

    const user = {
      name,
      email,
      password,
      telephone: cleanedPhone
    }

    let response = await createdUser(user);
    if (response.error) {
      setMessageError(response.message)
      setLoading(false);
      return
    }

    setLoading(false)
    if (response.method.includes("email")) {
      setMessageSuccess(response.method + " verifique a caixa de spam")
      return
    }
    setMessageSuccess(response.method)
  }

  const phoneMask = (value) => {
    if (!value) return ""
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{2})(\d)/, "($1) $2")
    value = value.replace(/(\d)(\d{4})$/, "$1-$2")
    return value
  }

  return (
    <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
      data-sidebar-position="fixed" data-header-position="fixed">
      <div
        className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center justify-content-center w-100">
          <div className="row justify-content-center w-100">
            <div className="col-md-8 col-lg-6 col-xxl-3">
              <div className="card mb-0">
                <form>
                  {!messageSuccess &&
                    <div className="card-body">
                      <a className="text-nowrap logo-img text-center d-block py-3 w-100">
                        <img src="../assets/images/logos/platformbotlogo.png" width="180" alt="" />
                      </a>
                      <p className="text-center">platformbot by FMS</p>
                      {messageError && <div class="alert alert-danger" role="alert">
                        {messageError}
                      </div>}
                      <div className={`mb-3 ${nameError ? 'has-error' : ''}`}>
                        <label className="form-label">Nome</label>
                        <input onChange={(e) => setName(e.target.value)} type="text" className={`form-control ${nameError ? 'is-invalid' : ''}`} aria-describedby="textHelp" />
                        {nameError && <div className="invalid-feedback">Campo obrigatório</div>}
                      </div>
                      <div className={`mb-3 ${emailError ? 'has-error' : ''}`}>
                        <label className="form-label">Email</label>
                        <input onChange={(e) => setEmail(e.target.value)} type='email' className={`form-control ${emailError ? 'is-invalid' : ''}`} />
                        {emailError && <div className="invalid-feedback">Campo obrigatório</div>}
                        <div className="form-text">Enviaremos um link de confirmação.</div>
                      </div>
                      <div className={`mb-3 ${phoneError ? 'has-error' : ''}`}>
                        <label className="form-label">Telefone (whatsapp)</label>
                        <input onInput={e => handlePhone(e)} className={`form-control ${phoneError ? 'is-invalid' : ''}`} aria-describedby="emailHelp" />
                        {phoneError && <div className="invalid-feedback">Campo obrigatório</div>}
                        <div className="form-text">Enviaremos um link de confirmação.</div>
                      </div>
                      <div className={`mb-4 ${passwordError ? 'has-error' : ''}`}>
                        <label className="form-label">Password</label>
                        <input onChange={(e) => setPassword(e.target.value)} type="password" className={`form-control ${passwordError ? 'is-invalid' : ''}`} />
                        {passwordError && <div className="invalid-feedback">Campo obrigatório</div>}
                      </div>
                      <button disabled={loading} onClick={(e) => createdNewUser(e)} className="btn btn-primary w-100 py-8 fs-4 mb-4 rounded-2">{loading == true ? 'Carregando...' : 'Cadastrar'}</button>
                      <div className="d-flex align-items-center justify-content-center">
                        <p className="fs-4 mb-0 fw-bold">Já possui conta?</p>
                        <a className="text-primary fw-bold ms-2" href="login">Voltar para o login</a>
                      </div>
                    </div>
                  }
                  {messageSuccess &&
                    <div className="card-body">
                      <a href="./index.html" class="text-nowrap logo-img text-center d-block py-3 w-100">
                        <img src="../assets/images/logos/platformbotlogo.png" width="180" alt="" />
                      </a>
                      <p>Um link de confirmação foi enviado em seu {messageSuccess}, após a confirmação faça o login</p>
                      <p>Não recebeu? por favor, entre em contato com o administrador do sistema, <a href="https://api.whatsapp.com/send?phone=5535998308037">Clique aqui</a> para falar com o suporte</p>
                      <a href="login">Voltar para o Login</a>
                    </div>
                  }
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register;
