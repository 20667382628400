const createdUser = async (requestData) => {
    let response = await fetch(`${process.env.REACT_APP_API_WEB_BOOT}user`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
    });

    if (response.status !== 200) {
        response = await response.json();
        let message = "Ocorreu um erro no cadastro";
        console.log(response.message )
        if(response.message == "User email already exists"){
            message = "E-mail já possui cadastro, escolha outro e-mail"
        }

        if(response.message == "User telephone already exists"){
            message = "Telefone já possui cadastro, escolha outro telefone"
        }
     
        return {
            message,
            error: true
        };
    }
    response = response.json();
    return response;
}

export default createdUser