
import Cookies from 'js-cookie';

const checkAuth = async () => {

    const authToken = Cookies.get('authToken');
    if (!authToken) {
        return false;
    }
    
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
    };


    let response = await fetch(`${process.env.REACT_APP_API_WEB_BOOT}auth`, {
        method: 'GET',
        headers
    })
    if (response.status !== 200) {
        return false
    }

    response = await response.json()

    Cookies.set('user', JSON.stringify({
        id: response.id,
        name: response.name,
        email: response.email,
        datePlan: response.date_plan,
        plan: response.plan,
        maximum_senders: response.maximum_senders,
        room: response.room
    }))

    return true
}

export default checkAuth