import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import checkLoginWp from '../../helper/user/checkLoginWp';
import deleteSession from '../../helper/user/deleteSession';
import { useNavigate } from 'react-router-dom';
import Plan from '../partial/plan';
import io from 'socket.io-client';

const QrCode = () => {
    const [loading, setLoading] = useState(false);
    const [loadingCheckLogged, setLoadingCheckLogged] = useState(true);
    const [qrCodeImg, setQrCodeImg] = useState('../assets/images/qrcode.jpg');
    const [conectadoWp, setConectadoWp] = useState(false);
    const [message, setMessage] = useState("");
    const [messageError, setMessageError] = useState("");
    const navigate = useNavigate();
    const socket = io('https://wss.platformbot.com.br');
    let intervalId

    useEffect(() => {
        socket.on('connect', () => {
            const romId = getRoom()
            socket.emit('join', { romId });
        });

        socket.on('receiveQrCode', (data) => {
            setQrCodeImg(data.base64Qr);
            let time = 40;
            let intervalIdQrCode = setInterval(async () => {
                if(time > 0){
                    time = time - 1;
                }
                setMessage(`Leia o qr code para continuar... você tem ${time} segundos para ler`);
                if (time == 0) {
                    if (!conectadoWp) {
                        await deleteSession()
                        clearInterval(intervalIdQrCode)
                        setMessage('Qr code expirado, tente novamente');
                        setQrCodeImg('../assets/images/qrcode.jpg');
                        setLoading(false);
                        setConectadoWp(false)
                    }
                }
            }, 1000)
        });

        socket.on('errorReceiveQrCode', async (data) => {
            await deleteSession()
            setMessageError('Houve um erro ao conectar, tente novamente');
            setQrCodeImg('../assets/images/qrcode.jpg');
            setLoading(false);
            setConectadoWp(false)
            clearInterval(intervalId);
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const getRoom = () => {
        let user = Cookies.get('user')
        user = JSON.parse(user)
        return user.room;
    }

    
    const checkWpLogged = async () => {
        const logged = await checkLoginWp()
        setConectadoWp(logged)
        setLoadingCheckLogged(false)
    }

    const desconect = async () => {
        await deleteSession()
        navigate('/home');
    }

    useEffect(() => {
        checkWpLogged()
    }, [])

    const qrCode = async () => {
        setMessageError("")
        setMessage("")
        setLoading(true);
        const authToken = Cookies.get('authToken');
        const romId = getRoom()
        fetch(`${process.env.REACT_APP_API_WEB_BOOT}bot?romId=${romId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            }
        }).then(async res => {
            if (res.status === 200) {
                navigate('/home');
            } 
        })
    }

    return (
        <div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-4">
                        {loadingCheckLogged && <p>Carregando...</p>}
                        {(conectadoWp == false && loadingCheckLogged == false) && <div class="card ">
                            <img src={qrCodeImg} class="card-img-top" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title">Conecte com sua conta whatsapp</h5>
                                <p class="card-text">Clique em conectar para gerar o qr code para acessar sua conta whatsaap</p>
                                <button disabled={loading} onClick={qrCode} class="btn btn-primary">{loading ? "Carregando..." : "Conectar"}</button>
                                <br></br>
                                <b>{message}</b>
                                <p>{messageError}</p>
                            </div>
                        </div>}
                        {(conectadoWp && loadingCheckLogged == false) && <div class="card card-img">
                            <img src="../assets/images/sucess.png" class="card-img-top" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title">Você esta conectado com sucesso no whatsapp</h5>
                                <button onClick={desconect} class="btn btn-danger mt-3">Desconectar</button>
                            </div>
                        </div>}
                    </div>
                    <div class="col-md-4">
                        <Plan/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QrCode