import React, { useEffect, useState } from 'react';
import getAllChatsGroups from '../../helper/user/getAllChatsGroups';
import { useParams } from 'react-router-dom';
import ExcelJS from 'exceljs';
import Cookies from 'js-cookie';

const GroupsView = () => {
    const { groupId } = useParams();
    const [allContacts, setAllContacts] = useState([]);
    const [nameGroup, setNameGroup] = useState('');
    const [loading, setLoading] = useState(false);
    const [pro, setPro] = useState(false);

    const checkPro = async () => {
        let user = Cookies.get('user')
        user = JSON.parse(user)
        setPro(user.plan)
    }

    const fetchContacts = async () => {
        setLoading(true);
        const contacts = await getAllChatsGroups();
        if (!contacts) {
            return [];
        }

        let group = contacts.find((group) => group.id.user === groupId);

        if (!group) {
            return [];
        }

        setNameGroup(group.contact.name);
        const groupContact = group.groupMetadata.participants;
        setAllContacts(groupContact);
        setLoading(false);
    };

    useEffect(() => {
        fetchContacts();
        checkPro();
    }, []);

    const exportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(`Contatos do Grupo ${nameGroup}`);

        // Adicionar cabeçalhos
        worksheet.columns = [
            { header: 'Contato', key: 'contact', width: 15 },
        ];

        // Adicionar dados
        allContacts.forEach((contact) => {
            worksheet.addRow({
                contact: contact.id.user,
            });
        });

        // Criar o arquivo Excel
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const fileName = `contatos_grupo_${nameGroup}.xlsx`;
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = href;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const exportToTxt = () => {
        const txtContent = allContacts.map((contact) => `${contact.id.user}`).join('\n');

        const blob = new Blob([txtContent], { type: 'text/plain' });
        const fileName = `contatos_grupo_${nameGroup}.txt`;
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = href;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="container-fluid">
            {(!pro) && (
                <div className="alert alert-danger" role="alert">
                    Atenção alguns de seus contatos estão mascarado com "***" para remover assine um plano pro, <a href="/profile">clique aqui</a>
                </div>
            )}
            <div className="col-lg-12 d-flex align-items-stretch">
                <div className="card w-100">
                    <div className="card-body p-4">

                        <div className='container-title-card'>
                            <h5 className="card-title fw-semibold mb-4">Grupo: {nameGroup}</h5>
                            <div className='help-user'>
                                <a target='_blank' href="https://www.youtube.com/@Platformbot-um5ok/videos" ><img className='help-img-yt' src="/assets/images/1384060.png" />Aprenda usar</a>
                            </div>
                        </div>

                        {!loading && <div>
                            <button className="btn btn-outline-primary m-1" onClick={exportToExcel}>Exportar para Excel</button>
                            <button className="btn btn-outline-primary m-1" onClick={exportToTxt}>Exportar para TXT</button>
                        </div>}
                        <div className="table-responsive">
                            <p>{loading && 'Carregando... aguarde... isso pode demorar depende da quantidade de dados em seu whatsapp'}</p>
                            {!loading &&
                                <table className="table text-nowrap mb-0 align-middle">
                                    <thead className="text-dark fs-4">
                                        <tr>
                                            <th className="border-bottom-0">
                                                <h6 className="fw-semibold mb-0">Contato</h6>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allContacts.map((contact, index) => (
                                            <tr key={index}>
                                                <td className="border-bottom-0">
                                                    <p className="mb-0 fw-normal">{contact.id.user}</p>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GroupsView;
