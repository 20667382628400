
import Cookies from 'js-cookie';

const createdScheduling = async (requestData) => {

    const authToken = Cookies.get('authToken');
    if (!authToken) {
        return false;
    }

    let response = await fetch(`${process.env.REACT_APP_API_WEB_BOOT}scheduling`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify(requestData)
    });

    if (response.status !== 200) {
        return false;
    }
    response = response.json();
    return response;
}

export default createdScheduling