import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from '../components/login/login';
import Register from '../components/register/register';
import Home from '../components/home/home';
import Contacts from '../components/contacts/contacts';
import Chats from '../components/chats/chats';
import Groups from '../components/groups/groups';
import GroupsView from '../components/groups/groupsView';
import Send from '../components/send/send';
import SendView from '../components/send/sendView';
import SendCreated from '../components/send/sendCreated';
import SendUpdate from '../components/send/sendUpdate'
import Confirm from '../components/login/confirm';
import Dashboard from '../components/dashboard/dashboard';
import Profile from '../components/profile/profile';
import PrivateRoute from './PrivateRoute';

export default props => (
  <Router>
    <Routes>
      <Route path="/" element={<PrivateRoute>{() => <Home />}</PrivateRoute>}/>
      <Route path="/profile" element={<PrivateRoute>{() => <Profile />}</PrivateRoute>}/>
      <Route path="/contacts" element={<PrivateRoute>{() => <Contacts />}</PrivateRoute>}/>
      <Route path="/dashboard" element={<PrivateRoute>{() => <Dashboard />}</PrivateRoute>}/>
      <Route path="/chats" element={<PrivateRoute>{() => <Chats />}</PrivateRoute>}/>
      <Route path="/groups" element={<PrivateRoute>{() => <Groups />}</PrivateRoute>}/>
      <Route path="/group/:groupId" element={<PrivateRoute>{() => <GroupsView />}</PrivateRoute>}/>
      <Route path="/send" element={<PrivateRoute>{() => <Send />}</PrivateRoute>}/>
      <Route path="/send/:sendId" element={<PrivateRoute>{() => <SendView />}</PrivateRoute>}/>
      <Route path="/send/new" element={<PrivateRoute>{() => <SendCreated />}</PrivateRoute>}/>
      <Route path="/send/edit/:sendId" element={<PrivateRoute>{() => <SendUpdate />}</PrivateRoute>}/>

      <Route path="/login" element={<Login />} />
      <Route path="/user/confirm" element={<Confirm />} />
      <Route path="/register" element={<Register />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </Router>
);
