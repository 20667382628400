
import Cookies from 'js-cookie';

const getAllGraphic = async (year, month) => {

    const authToken = Cookies.get('authToken');
    if (!authToken) {
        return false;
    }

    let response = await fetch(`${process.env.REACT_APP_API_WEB_BOOT}scheduling/totalstatus?month=${month}&year=${year}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }
    });

    if (response.status !== 200) {
        return false;
    }
    response = response.json();
    return response;
}

export default getAllGraphic