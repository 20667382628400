
import Cookies from 'js-cookie';

const updateScheduling = async (requestData, id) => {

    const authToken = Cookies.get('authToken');
    if (!authToken) {
        return false;
    }

    let response = await fetch(`${process.env.REACT_APP_API_WEB_BOOT}scheduling?scheduling_id=${id}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify(requestData)
    });

    if (response.status !== 200) {
        response = await response.json();
        return {
            status: false,
            message: response.message
        };
    }
    response = response.json();
    return {
        status: true,
        message: response.message
    };
}

export default updateScheduling