import Aside from '../../template/partial/aside'
import Header from '../../template/partial/header'
import Chats from '../../template/chats/chats'

const chats = () => {
    return (
        <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
        data-sidebar-position="fixed" data-header-position="fixed">
            <Aside/>

            <div class="body-wrapper">
                <Header/>
                <Chats/>
            </div>

        </div>
    )
}

export default chats