import React, { useEffect, useState } from 'react';
import getAllChatsGroups from '../../helper/user/getAllChatsGroups';
import checkLoginWp from '../../helper/user/checkLoginWp';

const Groups = () => {
    const [allContacts, setAllContacts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [conectadoWp, setConectadoWp] = useState(true);

    const fetchContacts = async () => {
        setLoading(true);
        const contacts = await getAllChatsGroups();
        if (!contacts) {
            return [];
        }
        setAllContacts(contacts);
        setLoading(false);
    };

    const checkLogin = async () => {
        const logged = await checkLoginWp();
        setConectadoWp(logged);
    };

    useEffect(() => {
        fetchContacts();
        checkLogin();
    }, []);

    return (
        <div className="container-fluid">
            {!conectadoWp && (
                <div className="alert alert-warning" role="alert">
                    Para visualizar essa página você precisa estar logado no WhatsApp, <a href="/home">clique aqui</a> para realizar o login
                </div>
            )}
            {conectadoWp &&
                <div className="col-lg-12 d-flex align-items-stretch">
                    <div className="card w-100">
                        <div className="card-body p-4">

                            <div className='container-title-card'>
                                <h5 className="card-title fw-semibold mb-4">Grupos</h5>
                                <div className='help-user'>
                                    <a target='_blank' href="https://www.youtube.com/@Platformbot-um5ok/videos" ><img className='help-img-yt' src="/assets/images/1384060.png" />Aprenda usar</a>
                                </div>
                            </div>

                            <div className="table-responsive">
                                <p>{loading && 'Carregando... aguarde... isso pode demorar depende da quantidade de dados em seu WhatsApp'}</p>
                                {!loading && (
                                    <table className="table text-nowrap mb-0 align-middle">
                                        <thead className="text-dark fs-4">
                                            <tr>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Contato</h6>
                                                </th>
                                                <th className="border-bottom-0">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allContacts.map((contact, index) => (
                                                <tr key={index}>
                                                    <td className="border-bottom-0">
                                                        <h6 className="fw-semibold mb-1">{contact.groupMetadata.subject}</h6>
                                                        <span className="fw-normal">{contact.name}</span>
                                                    </td>
                                                    <td className="border-bottom-0">
                                                        <a href={`group/${contact.id.user}`}>Ver participantes</a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default Groups;
