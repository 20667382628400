import React, { useEffect, useState } from 'react';
import { Route, Navigate } from 'react-router-dom';
import checkAuth from "../helper/user/checkAuth"; // Import the authentication check function

const PrivateRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const checkAuthentication = async () => {
    try {
      const result = await checkAuth();
      setIsAuthenticated(result);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);
  
  if (isLoading) {
    return <div className='loading-container'>
      <img src="../assets/images/loading.gif" />
      <p>Carregando...</p>
    </div>;
  }

  return isAuthenticated ? children() : <Navigate to="/login" />;
};

export default PrivateRoute;
