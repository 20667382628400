import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';

const Aside = () => {
    library.add(faChartLine);
    const [pro, setPro] = useState(true)
    const [date, setDate] = useState('')

    const checkPro = async () => {
        let user = Cookies.get('user')
        user = JSON.parse(user)
        setPro(user.plan)
        setDate(user.datePlan)
    }

    useEffect(() => {
        checkPro()
    }, [])

    return (
        <aside class="left-sidebar">
            <div>
                <div class="brand-logo d-flex align-items-center justify-content-between">
                    <a href="./index.html" class="text-nowrap logo-img">
                        <img src="../assets/images/logos/platformbotlogo.png" width="180" alt="" />
                    </a>
                    <div class="close-btn d-xl-none d-block sidebartoggler cursor-pointer" id="sidebarCollapse">
                        <i class="ti ti-x fs-8 close-btn"></i>
                    </div>
                </div>
                <nav class="sidebar-nav scroll-sidebar" data-simplebar="">
                    <ul id="sidebarnav">
                        <li class="nav-small-cap">
                            <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
                            <span class="hide-menu">Menu</span>
                        </li>
                        <li class="sidebar-item">
                            <a class="sidebar-link" href="/" aria-expanded="false">
                                <span>
                                    <i class="ti ti-layout-dashboard"></i>
                                </span>
                                <span class="hide-menu">Home</span>
                            </a>
                        </li>
                        <li class="sidebar-item">
                            <a class="sidebar-link" href="/dashboard" aria-expanded="false">
                                <span>
                                <FontAwesomeIcon icon="fa-solid fa-chart-line" />
                                </span>
                                <span class="hide-menu">Dashboard</span>
                            </a>
                        </li>
                        <div>
                            <li class="sidebar-item">
                                <a class="sidebar-link" href="/send" aria-expanded="false">
                                    <span>
                                        <i class="ti ti-mail-fast"></i>
                                    </span>
                                    <span class="hide-menu">Enviar</span>
                                </a>
                            </li>
                            <li class="sidebar-item">
                                <a class="sidebar-link" href="/contacts" aria-expanded="false">
                                    <span>
                                        <i class="ti ti-address-book"></i>
                                    </span>
                                    <span class="hide-menu">Contatos</span>
                                </a>
                            </li>
                            <li class="sidebar-item">
                                <a class="sidebar-link" href="/chats" aria-expanded="false">
                                    <span>
                                        <i class="ti ti-brand-hipchat"></i>
                                    </span>
                                    <span class="hide-menu">Chats</span>
                                </a>
                            </li>
                            <li class="sidebar-item">
                                <a class="sidebar-link" href="/groups" aria-expanded="false">
                                    <span>
                                        <i class="ti ti-users"></i>
                                    </span>
                                    <span class="hide-menu">Grupos</span>
                                </a>
                            </li>
                        </div>
                    </ul>
                    <div class="unlimited-access hide-menu bg-light-primary position-relative mb-7 mt-5 rounded">
                        {!pro && <div class="d-flex">
                            <div class="unlimited-access-title me-3">
                                <h6 class="fw-semibold fs-4 mb-6 text-dark w-85">Atualize para o plano Pro</h6>                               
                                <a href="profile"  class="btn btn-primary fs-2 fw-semibold lh-sm">Ver planos</a>
                            </div>
                            <div class="unlimited-access-img">
                                <img src="../assets/images/backgrounds/rocket.png" alt="" class="img-fluid" />
                            </div>
                        </div>
                        }
                        {pro == 1 &&
                            <div>
                                <h5>Seu plano</h5>
                                <span>Plano: Pago</span><br></br>
                                <span>Ativo até {date}</span>
                            </div>
                        }
                    </div>
                </nav>
            </div>
        </aside>
    )
}

export default Aside