
const getPaymentUrl = async (requestData) => {

    let response = await fetch(`${process.env.REACT_APP_API_PAYMENT}checkout/monthly`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
    });

    if (response.status !== 200) {
        return false;
    }
    response = response.json();
    return response;
}

export default getPaymentUrl