import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import Plan from '../partial/plan';
import getAllCheckouts from '../../helper/user/getAllCheckouts'

const Profile = () => {
    const [user, setUser] = useState()
    const [checkout, setCheckout] = useState([])

    const getUser = () => {
        let user = Cookies.get('user')
        user = JSON.parse(user)
        setUser(user)
    }

    const allCheckouts = async () => {
        const checkout = await getAllCheckouts()
        setCheckout(checkout)
    }


    useEffect(() => {
        getUser()
        allCheckouts()
    }, []);

    return (
        <div className="container-fluid">
            <h5 class="card-title fw-semibold mb-4">Meu perfil</h5>
            <div class="container-profile">
                <div className='mt-4'>
                    <div >
                        <label className="form-label">Nome</label>
                        <input value={user?.name} disabled type="text" className={`form-control`} aria-describedby="textHelp" />
                    </div>
                    <div className='mt-3'>
                        <label className="form-label">Email</label>
                        <input value={user?.email} disabled type="text" className={`form-control`} aria-describedby="textHelp" />
                    </div>
                    <div className='mt-3'>
                        <label className="form-label">Plano</label>
                        <input value={user?.plan == 1 ? `Ativo até: ${user?.datePlan}` : 'Free'} disabled type="text" className={`form-control`} aria-describedby="textHelp" />
                    </div>
                    {!user?.plan &&
                        <div className='mt-3'>
                            <label className="form-label">Crédito de envio:</label>
                            <input value={user?.maximum_senders} disabled type="text" className={`form-control`} aria-describedby="textHelp" />
                        </div>}
                </div>
                <div>
                    <Plan />
                </div>
            </div>
            <h5 class="card-title fw-semibold mt-5 mb-2">Histórico de checkout</h5>
            <p>Após 3 dias os checkouts abertos sem confirmação de pagamento, serão cancelado automaticamente</p>
            <div className='transition-table'>
                <table className="table text-nowrap mb-0 align-middle">
                    <thead className="text-dark fs-4">
                        <tr>
                            <th className="border-bottom-0">
                                <h6 className="fw-semibold mb-0">#id</h6>
                            </th>
                            <th className="border-bottom-0">
                                <h6 className="fw-semibold mb-0">Order reference</h6>
                            </th>
                            <th className="border-bottom-0">
                                <h6 className="fw-semibold mb-0">Valor</h6>
                            </th>
                            <th className="border-bottom-0">
                                <h6 className="fw-semibold mb-0">Tipo</h6>
                            </th>
                            <th className="border-bottom-0">
                                <h6 className="fw-semibold mb-0">Status</h6>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {checkout.map((data, index) => (
                            <tr key={index}>
                                <td className="border-bottom-0">
                                    <p className="mb-0 fw-normal">{data.id}</p>
                                </td>
                                <td className="border-bottom-0">
                                    <p className="mb-0 fw-normal">{data.external_reference}</p>
                                </td>
                                <td className="border-bottom-0">
                                    <p className="mb-0 fw-normal">{data.unit_price}</p>
                                </td>
                                <td className="border-bottom-0">
                                    <p className="mb-0 fw-normal">{data.title}</p>
                                </td>
                                <td className="border-bottom-0">
                                    <p className="mb-0 fw-normal">{data.status == null ? "Pendente": data.status}</p>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Profile