import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import getPaymentUrl from "../../helper/user/getPaymentUrl"
import userIsFms from '../../helper/user/userIsFms';

const Plan = () => {
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState()
    const [isFMSUser, setIsFMSUser] = useState(false)
    const [infoFms, setInfoFms] = useState(false)

    const getUser = async () => {
        let user = Cookies.get('user')
        user = JSON.parse(user)
        if(user.name.includes('FMS')){
            setInfoFms("Para assinantes FMS, preço único de 29,90. Assine o FMS para obter o desconto")
            const userFms = await userIsFms(user.email)
            if(userFms.user == "PRO") {
                setIsFMSUser(true)
            }
        }
        setUser(user)
    }

    const getUrlPayment = async () => {
        setLoading(true)
        let user = Cookies.get('user')
        user = JSON.parse(user)
        const url = await getPaymentUrl({
            userId: user.id
        })
        window.location.href = url.redirect_url
    }

    useEffect(() => {
        getUser()
    }, []);

    return (
        <div>
                <div>
                    {!user?.plan &&
                        <section class="price_plan_area section_padding_130_80" id="pricing">
                            <div class="container">
                                <div class="row justify-content-center">
                                    <div class="single_price_plan wow fadeInUp" data-wow-delay="0.2s" style={{/*visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;*/ }}>
                                        <div class="title">
                                            <h3>Plano mensal</h3>
                                            <div class="line"></div>
                                        </div>
                                        <div class="price">
                                            <h4>{isFMSUser ? "R$ 29,90" : "R$ 49,90"}</h4>
                                            <br></br>  
                                            {(infoFms && !isFMSUser) && <span>{infoFms}</span>}
                                            {isFMSUser &&  <span>Desconto exclusivo para assinates FMS</span>}
                                        </div>
                                        <div class="description">
                                            <p><i class="lni lni-checkmark-circle"></i>Todas funcionalidades</p>
                                            <p><i class="lni lni-checkmark-circle"></i>Envios ilimitado</p>
                                            <p><i class="lni lni-checkmark-circle"></i>Suporte</p>
                                        </div>
                                        <div class="button"><button  disabled={loading} class="btn btn-primary fs-2 fw-semibold lh-sm" onClick={getUrlPayment} >{!loading ? "Assinar" : "carregando..."}</button></div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    }
                </div>
           
        </div>
    )
}

export default Plan