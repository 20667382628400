import Aside from '../../template/partial/aside'
import Header from '../../template/partial/header'
import Profile from '../../template/profile/profile'

const profile = () => {
    return (
        <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">

            <Aside/>

            <div class="body-wrapper">
                <Header/>
                <Profile/>
            </div>

        </div>
    )
}

export default profile