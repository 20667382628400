import React, { useEffect, useState } from 'react';
import updateScheduling from '../../helper/user/updateScheduling';
import getAllScheduling from '../../helper/user/getAllScheduling';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import EmojiPicker from 'emoji-picker-react';
import { useParams } from 'react-router-dom';

const SendUpdate = () => {
    const { sendId } = useParams();
    const [openEmogis, setOpenEmogis] = useState(false);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [fileContent, setFileContent] = useState('');
    const [startDate, setStartDate] = useState('');
    const [messageInterval, setMessageInterval] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const [nameError, setNameError] = useState(false);
    const [fileError, setFileError] = useState(false);
    const [startDateError, setStartDateError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [messageIntervalError, setMessageIntervalError] = useState(false);
    const [maximumSender, setMaximumSender] = useState(1);
    const [plan, setPlan] = useState(0);

    const checkPlan = () => {
        let user = Cookies.get('user');
        user = JSON.parse(user);
        setMaximumSender(parseInt(user.maximum_senders));
        setPlan(parseInt(user.plan));
    };

    const getScheduling = async () => {
        const allData = await getAllScheduling();
        const data = allData.filter(scheduling => parseInt(scheduling.id) === parseInt(sendId))
        setName(data[0].name);
        setMessageInterval(data[0].time);
        setMessage(data[0].message);
    }

    useEffect(() => {
        checkPlan();
        getScheduling();
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target.result;
                const cleanedContent = content.replace(/\r/g, '');
                const lines = cleanedContent.split('\n');
                if (lines.length > 200) {
                    setFileError(true);
                } else {
                    setFileContent(lines);
                    setFileError(false);
                }
            };
            reader.readAsText(file);
        }
    };

    function isValidTimeFormat(value) {
        const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
        return timeRegex.test(value);
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        if (!name || !messageInterval || !message) {
            setNameError(!name);
            setMessageIntervalError(!messageInterval);
            setMessageError(!message);
            return;
        }

        if (!isValidTimeFormat(messageInterval) || messageInterval < '00:00:30') {
            setMessageIntervalError(true);
            return;
        }

        setLoading(true);
        const body = {
            message,
            name
        };

        const response = await updateScheduling(body, sendId);
        if (!response.status) {
            alert('Erro ao criar agendamento, tente novamente');
            setLoading(false);
            return;
        }
        navigate('/send');
    };

    const setValueMessage = (event) => {
        setMessage(event.target.value);
    };
    const emojiSelect = (emojiData, event) => {
        setOpenEmogis(false);
        setMessage(prevMessage => prevMessage + emojiData.emoji);
    };

    return (
        <div className="container-fluid">
            {plan == 0 && maximumSender <= 0 && <div class="alert alert-danger" role="alert">
                Você já ultrapassou o limite de mensagem no plano gratuito, suas mensagem não serão mais enviadas, atualize para continuar usando
            </div>}
            <div className="col-lg-12 d-flex align-items-stretch">
                <div className="card w-100">
                    <div className="card-body p-4">
                       
                        <div className='container-title-card'>
                            <h5 className="card-title fw-semibold mb-4">Editar agendamento de envio</h5>
                            <div className='help-user'>
                                <a target='_blank' href="https://www.youtube.com/@Platformbot-um5ok/videos" ><img className='help-img-yt' src="/assets/images/1384060.png" />Aprenda usar</a>
                            </div>
                        </div>

                        <div>
                            <a href="/send" className="btn btn-outline-primary m-1">
                                Voltar
                            </a>
                        </div>

                        <form>
                            <div className={`mb-3 mt-2 ${nameError ? 'has-error' : ''}`}>
                                <label className="form-label">Nome:</label>
                                <input
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                        setNameError(false); // Limpar o erro quando o usuário começar a digitar
                                    }}
                                    className={`form-control ${nameError ? 'is-invalid' : ''}`}
                                />
                                {nameError && <div className="invalid-feedback">Campo obrigatório</div>}
                                <div id="emailHelp" className="form-text">
                                    Escolha o nome do seu agendamento.
                                </div>
                            </div>
                            <div className={`mb-3 mt-2 ${messageError ? 'has-error' : ''}`}>
                                <label className="form-label">Mensagem:</label>
                                <textarea
                                    value={message}
                                    onChange={(e) => {
                                        setValueMessage(e);
                                        setMessageError(false);
                                    }}
                                    className={`form-control heigth-textarea ${messageError ? 'is-invalid' : ''}`}
                                ></textarea>
                                {openEmogis && <p onClick={() => setOpenEmogis(!openEmogis)}>🥲Fechar</p>}
                                {!openEmogis && <p onClick={() => setOpenEmogis(!openEmogis)}>😄Emogis</p>}
                                {openEmogis &&
                                <EmojiPicker
                                    onEmojiClick={emojiSelect}
                                />}
                                {messageError && <div className="invalid-feedback">Campo obrigatório</div>}
                                <div className="form-text">Configure a mensagem para ser enviada.</div>
                            </div>
                            {(plan == 0 && maximumSender > 0 || plan == 1) && <button disabled={loading} onClick={handleFormSubmit} className="btn btn-outline-primary m-1">
                                {loading ? 'Enviando...' : 'Atualizar'}
                            </button>}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SendUpdate;
